/* Media objects
   Source: http://stubbornella.org/content/?p=497

example:

<div class="media">
    <div class="float-l">
        <img class="media-object" src="i/icon_views_74.png" height="74" width="74" alt=""/>
    </div>
    <div class="media-body">
        <h4 class="media-heading">Share your views on the news</h4>
        <p class="discreet">Comment on articles, and reply to others.</p>
    </div>    
</div>   
*/

/* Clear the floats */
.media-body {
  overflow: hidden;
  zoom: 1;
}

/* For images and videos, set to block */
.media-object {
  display: block;
}

/* Reset margins on headings for tighter default spacing */
.media-heading {
  margin: 0 0 5px;
  font-weight: normal;
}

/* Media image alignment */
.media > .media-float-l{
    margin-right: 10px;
}
.media > .media-float-r{
    margin-left: 10px;
}

.media-float-l{
  float: left;
}
.media-float-r{
  float: right;
}
